import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBtn,
    {
      attrs: {
        disabled: _vm.disabled,
        id: "btn-investor-table",
        loading: _vm.loading,
      },
      on: { click: _vm.handleClick },
    },
    [
      _vm.icon
        ? _c("font-awesome-icon", {
            staticClass: "btn-icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }