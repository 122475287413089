import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c(
        "a",
        {
          staticClass: "allocation-formalization-back",
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "allocation-formalization-back-icon",
            attrs: { icon: "fa-solid fa-arrow-left" },
          }),
          _c(
            "span",
            {
              staticClass: "allocation-formalization-back-text",
              attrs: { "data-test": "Dashboard:SignContracts:Formalization" },
            },
            [_vm._v(_vm._s(_vm.$t("formalization_title")))]
          ),
        ],
        1
      ),
      _vm.showAllocationData
        ? _c("h3", { staticClass: "page-subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("formalization_subtitle")) + " "),
          ])
        : _vm._e(),
      _vm.gs.isMobile()
        ? _c(
            VCard,
            {
              staticClass: "company-card mx-auto",
              attrs: { "max-width": "348" },
            },
            [
              _c(
                VCardActions,
                { staticClass: "pa-2", staticStyle: { "max-height": "35px" } },
                [
                  _c("h3", { staticClass: "company-title pl-2" }, [
                    _vm._v(_vm._s(_vm.allocation.Company.Name)),
                  ]),
                  _c(VSpacer),
                  _c("h4", { staticClass: "company-action" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.show
                            ? _vm.$t("close_summary")
                            : _vm.$t("open_summary")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.show = !_vm.show
                        },
                      },
                    },
                    [
                      _c(VIcon, [
                        _vm._v(
                          _vm._s(
                            _vm.show ? "mdi-chevron-up" : "mdi-chevron-down"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VExpandTransition, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.show,
                        expression: "show",
                      },
                    ],
                  },
                  [
                    _c(VCardText, [
                      _c("p", { staticClass: "company-content mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.convert_date(_vm.allocation.Timestamp)) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "company-content mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatToCurrency(
                                _vm.allocation.Currency,
                                _vm.allocation.Value
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            VCard,
            { staticClass: "company-card mx-auto pa-2" },
            [
              _c(VSimpleTable, {
                staticClass: "bg-white",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("th", { staticClass: "text-left" }, [
                            _c("h3", { staticClass: "company-title pl-2" }, [
                              _vm._v(_vm._s(_vm.allocation.Company.Name)),
                            ]),
                          ]),
                          _c("th", { staticClass: "text-left" }, [
                            _c(
                              "p",
                              {
                                staticClass: "company-content ma-1",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.convert_date(_vm.allocation.Timestamp)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("th", { staticClass: "text-left" }, [
                            _c(
                              "p",
                              {
                                staticClass: "company-content ma-1",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        _vm.allocation.Currency,
                                        _vm.allocation.Value
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
      _vm.showAllocationData
        ? _c(
            "div",
            {
              attrs: { "data-test": "Dashboard:SignContracts:AllocationData" },
            },
            [
              _vm.gs.isMobile()
                ? _c(
                    VCard,
                    { staticClass: "main-card pa-2" },
                    [
                      _vm.allocation.InvestmentVehicle.ClosingType ===
                      _vm.vehicleClosingOptionsEnum.CLOSING_LLC
                        ? _c(
                            VCard,
                            {
                              staticClass: "content-card pa-2 mx-1 my-6",
                              attrs: { color: "white" },
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pa-0 pl-3",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.allocation
                                        .SubscriptionAgreementSignatureDate
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-date",
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:SubscriptionAgreementSignatureDate",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("sign_date")) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.convert_date(
                                                      _vm.allocation
                                                        .SubscriptionAgreementSignatureDate
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "content-title" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("subscription_agreement")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      !_vm.allocation
                                        .SignedSubscriptionAgreement
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-status",
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:UnsignedSubscriptionAgreement",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("not_signed")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "content-status",
                                              staticStyle: {
                                                color: "var(--dark)",
                                              },
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:SignedSubscriptionAgreement",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$tc("signed", 1)) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticStyle: {
                                        margin: "auto",
                                        "text-align": "end",
                                      },
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      !_vm.allocation
                                        .SignedSubscriptionAgreement
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.allocation
                                                      .SubscriptionAgreementSignatureKey,
                                                  "data-test":
                                                    "Dashboard:SignContracts:SignSubscriptionAgreement",
                                                  title: _vm.$t("sign"),
                                                },
                                                on: {
                                                  btnFunction: function (
                                                    $event
                                                  ) {
                                                    return _vm.open_dialog(
                                                      _vm.allocation
                                                        .SubscriptionAgreementSignatureKey,
                                                      _vm.docTypeEnum
                                                        .SubscriptionAgreement
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.allocation.SubscriptionAgreementSaved
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  title: _vm.$t("download"),
                                                  "data-test":
                                                    "Dashboard:SignContracts:DownloadSubscriptionAgreement",
                                                },
                                                on: {
                                                  btnFunction: function (
                                                    $event
                                                  ) {
                                                    return _vm.downloadDocuments(
                                                      _vm.userFileEnums
                                                        .SUBSCRIPTION_AGREEMENT
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allocation.InvestmentVehicle.ClosingType ===
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c(
                            VCard,
                            {
                              staticClass: "content-card pa-2 mx-1 my-6",
                              attrs: { color: "white" },
                            },
                            [
                              _vm.allocation.InvestmentVehicle.ClosingType ===
                              _vm.vehicleClosingOptionsEnum.CLOSING_SA
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "pa-0 pl-3",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _vm.allocation.NewsletterSignatureDate
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "content-date",
                                                  attrs: {
                                                    "data-test":
                                                      "Dashboard:SignContracts:NewsletterSubscriptionSignatureDate",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("sign_date")
                                                      ) +
                                                      ": " +
                                                      _vm._s(
                                                        _vm.convert_date(
                                                          _vm.allocation
                                                            .NewsletterSignatureDate
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "content-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "newsletter_subscription"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          !_vm.allocation
                                            .SignedNewsletterSubscription
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "content-status",
                                                  attrs: {
                                                    "data-test":
                                                      "Dashboard:SignContracts:UnsignedNewsletterSubscription",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("not_signed")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "content-status",
                                                  staticStyle: {
                                                    color: "var(--dark)",
                                                  },
                                                  attrs: {
                                                    "data-test":
                                                      "Dashboard:SignContracts:SignedNewsletterSubscription",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc("signed", 1)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          staticStyle: {
                                            margin: "auto",
                                            "text-align": "end",
                                          },
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          !_vm.allocation
                                            .SignedNewsletterSubscription
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "btn-container",
                                                },
                                                [
                                                  _c("DxaButtonToTable", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.allocation
                                                          .NewsletterSubscriptionSignatureKey,
                                                      "data-test":
                                                        "Dashboard:SignContracts:SignNewsletterSubscription",
                                                      title: _vm.$t("sign"),
                                                    },
                                                    on: {
                                                      btnFunction: function (
                                                        $event
                                                      ) {
                                                        return _vm.open_dialog(
                                                          _vm.allocation
                                                            .NewsletterSubscriptionSignatureKey,
                                                          _vm.docTypeEnum
                                                            .NewsletterSubscription
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.allocation
                                            .NewsletterSubscriptionSaved
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "btn-container",
                                                },
                                                [
                                                  _c("DxaButtonToTable", {
                                                    attrs: {
                                                      title: _vm.$t("download"),
                                                      "data-test":
                                                        "Dashboard:SignContracts:DownloadNewsletterSubscription",
                                                    },
                                                    on: {
                                                      btnFunction: function (
                                                        $event
                                                      ) {
                                                        return _vm.downloadDocuments(
                                                          _vm.userFileEnums
                                                            .NEWS_LETTER_SUBSCRIPTION
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allocation.InvestmentVehicle.ClosingType ===
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c(
                            VCard,
                            {
                              staticClass: "content-card pa-2 mx-1 my-6",
                              attrs: { color: "white" },
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pa-0 pl-3",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.allocation.AdhesionTermSignatureDate
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-date",
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:AdhesionTermSignatureDate",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("sign_date")) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.convert_date(
                                                      _vm.allocation
                                                        .AdhesionTermSignatureDate
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "content-title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$tc("adhesion_term", 1))
                                          ),
                                        ]
                                      ),
                                      !_vm.allocation.SignedTerm
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "content-status",
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:UnsignedAdhesionTerm",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("not_signed")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "content-status",
                                              staticStyle: {
                                                color: "var(--dark)",
                                              },
                                              attrs: {
                                                "data-test":
                                                  "Dashboard:SignContracts:SignedAdhesionTerm",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$tc("signed", 1)) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticStyle: {
                                        margin: "auto",
                                        "text-align": "end",
                                      },
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      !_vm.allocation.SignedTerm
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.allocation
                                                      .AdhesionTermSignatureKey,
                                                  "data-test":
                                                    "Dashboard:SignContracts:SignAdhesionTerm",
                                                  title: _vm.$t("sign"),
                                                },
                                                on: {
                                                  btnFunction: function (
                                                    $event
                                                  ) {
                                                    return _vm.open_dialog(
                                                      _vm.allocation
                                                        .AdhesionTermSignatureKey,
                                                      _vm.docTypeEnum
                                                        .AdhesionTerm
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.allocation.AdhesionTermSaved
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  title: _vm.$t("download"),
                                                  "data-test":
                                                    "Dashboard:SignContracts:DownloadAdhesionTerm",
                                                },
                                                on: {
                                                  btnFunction: function (
                                                    $event
                                                  ) {
                                                    return _vm.downloadDocuments(
                                                      _vm.userFileEnums
                                                        .ADHESION_TERM
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allocation.InvestmentVehicle.ClosingType ===
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c(
                            VCard,
                            {
                              staticClass: "content-card pa-2 mx-1 my-6",
                              attrs: { color: "white" },
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pa-0 pl-3",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "content-date" },
                                        [_vm._v("-")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "content-title" },
                                        [_vm._v(_vm._s(_vm.$tc("bylaws", 1)))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "content-status" },
                                        [_vm._v("-")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticStyle: {
                                        margin: "auto",
                                        "text-align": "end",
                                      },
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.allocation.InvestmentVehicle.BylawsUrl
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.allocation
                                                      .InvestmentVehicle
                                                      .BylawsUrl,
                                                  title: _vm.$t("download"),
                                                  loading:
                                                    _vm.fileDownloading ==
                                                    _vm.allocation
                                                      .InvestmentVehicle
                                                      .BylawsUrl,
                                                  "data-test":
                                                    "Dashboard:SignContracts:DownloadBylaws",
                                                },
                                                on: {
                                                  btnFunction:
                                                    _vm.download_bylaws,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.allocation.InvestmentVehicle.ClosingType ===
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c(
                            VCard,
                            {
                              staticClass: "content-card pa-2 mx-1 my-6",
                              attrs: { color: "white" },
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pa-0 pl-3",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "content-date" },
                                        [_vm._v("-")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "content-title" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$tc(
                                                  "shareholders_agreement",
                                                  1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "content-status" },
                                        [_vm._v("-")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticStyle: {
                                        margin: "auto",
                                        "text-align": "end",
                                      },
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.allocation.InvestmentVehicle
                                        .ShareholderAgreementUrl
                                        ? _c(
                                            "div",
                                            { staticClass: "btn-container" },
                                            [
                                              _c("DxaButtonToTable", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.allocation
                                                      .InvestmentVehicle
                                                      .ShareholderAgreementUrl,
                                                  title: _vm.$t("download"),
                                                  loading:
                                                    _vm.fileDownloading ==
                                                    _vm.allocation
                                                      .InvestmentVehicle
                                                      .ShareholderAgreementUrl,
                                                  "data-test":
                                                    "Dashboard:SignContracts:DownloadShareholderAgreement",
                                                },
                                                on: {
                                                  btnFunction:
                                                    _vm.download_shareholder,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(VSimpleTable, { staticClass: "mt-3 bg-white" }, [
                        _c(
                          "thead",
                          {
                            staticClass: "text-center",
                            attrs: { id: "investor-table-header" },
                          },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  attrs: {
                                    colspan: "2",
                                    id: "bank-table-title",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("bank_account_transfer_info")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "tbody",
                          { attrs: { id: "bank-row" } },
                          _vm._l(_vm.bankInfo, function (item, i) {
                            return _c(
                              "tr",
                              { key: i, staticClass: "content-row" },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "investor-bank-table-item",
                                    attrs: { width: "60px" },
                                  },
                                  [_vm._v(" " + _vm._s(item.text) + " ")]
                                ),
                                item.value
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "investor-bank-table-item investor-bank-table-item-value",
                                      },
                                      [_vm._v(" " + _vm._s(item.value) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    VCard,
                    { staticClass: "main-card pa-5" },
                    [
                      _c(VSimpleTable, {
                        staticClass: "mb-4 bg-white",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "thead",
                                    {
                                      staticClass: "text-center",
                                      attrs: { id: "investor-table-header" },
                                    },
                                    [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(_vm.$t("contracts"))),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(_vm.$t("sign_date"))),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(_vm.$t("status"))),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(_vm.$t("actions"))),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c("tbody", [
                                    _vm.allocation.InvestmentVehicle
                                      .ClosingType ===
                                    _vm.vehicleClosingOptionsEnum.CLOSING_LLC
                                      ? _c(
                                          "tr",
                                          { staticClass: "content-row" },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "content-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "subscription_agreement"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm.allocation
                                              .SubscriptionAgreementSignatureDate
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:SubscriptionAgreementSignatureDate",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.convert_date(
                                                            _vm.allocation
                                                              .SubscriptionAgreementSignatureDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                            !_vm.allocation
                                              .SignedSubscriptionAgreement
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:UnsignedSubscriptionAgreement",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("not_signed")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    staticStyle: {
                                                      color: "var(--dark)",
                                                    },
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:SignedSubscriptionAgreement",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$tc("signed", 1)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                            _c("td", [
                                              !_vm.allocation
                                                .SignedSubscriptionAgreement
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.allocation
                                                              .SubscriptionAgreementSignatureKey,
                                                          "data-test":
                                                            "Dashboard:SignContracts:SignSubscriptionAgreement",
                                                          title: _vm.$t("sign"),
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.open_dialog(
                                                                _vm.allocation
                                                                  .SubscriptionAgreementSignatureKey,
                                                                _vm.docTypeEnum
                                                                  .SubscriptionAgreement
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.allocation
                                                .SubscriptionAgreementSaved
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          title:
                                                            _vm.$t("download"),
                                                          "data-test":
                                                            "Dashboard:SignContracts:DownloadSubscriptionAgreement",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.downloadDocuments(
                                                                _vm
                                                                  .userFileEnums
                                                                  .SUBSCRIPTION_AGREEMENT
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.allocation.InvestmentVehicle
                                      .ClosingType ===
                                    _vm.vehicleClosingOptionsEnum.CLOSING_SA
                                      ? _c(
                                          "tr",
                                          { staticClass: "content-row" },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "content-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "newsletter_subscription"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm.allocation
                                              .NewsletterSignatureDate
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:NewsletterSubscriptionSignatureDate",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.convert_date(
                                                            _vm.allocation
                                                              .NewsletterSignatureDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                            !_vm.allocation
                                              .SignedNewsletterSubscription
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:UnsignedNewsletterSubscription",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("not_signed")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    staticStyle: {
                                                      color: "var(--dark)",
                                                    },
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:SignedNewsletterSubscription",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$tc("signed", 1)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                            _c("td", [
                                              !_vm.allocation
                                                .SignedNewsletterSubscription
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.allocation
                                                              .NewsletterSubscriptionSignatureKey,
                                                          "data-test":
                                                            "Dashboard:SignContracts:SignNewsletterSubscription",
                                                          title: _vm.$t("sign"),
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.open_dialog(
                                                                _vm.allocation
                                                                  .NewsletterSubscriptionSignatureKey,
                                                                _vm.docTypeEnum
                                                                  .NewsletterSubscription
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.allocation
                                                .NewsletterSubscriptionSaved
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          title:
                                                            _vm.$t("download"),
                                                          "data-test":
                                                            "Dashboard:SignContracts:DownloadNewsletterSubscription",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.downloadDocuments(
                                                                _vm
                                                                  .userFileEnums
                                                                  .NEWS_LETTER_SUBSCRIPTION
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.allocation.InvestmentVehicle
                                      .ClosingType ===
                                    _vm.vehicleClosingOptionsEnum.CLOSING_SA
                                      ? _c(
                                          "tr",
                                          { staticClass: "content-row" },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "content-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc("adhesion_term", 1)
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.allocation
                                              .AdhesionTermSignatureDate
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:AdhesionTermSignatureDate",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.convert_date(
                                                            _vm.allocation
                                                              .AdhesionTermSignatureDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "content-date",
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                            !_vm.allocation.SignedTerm
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:UnsignedAdhesionTerm",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("not_signed")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "content-status",
                                                    staticStyle: {
                                                      color: "var(--dark)",
                                                    },
                                                    attrs: {
                                                      "data-test":
                                                        "Dashboard:SignContracts:SignedAdhesionTerm",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$tc("signed", 1)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                            _c("td", [
                                              !_vm.allocation.SignedTerm
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          title: _vm.$t("sign"),
                                                          disabled:
                                                            !_vm.allocation
                                                              .AdhesionTermSignatureKey,
                                                          "data-test":
                                                            "Dashboard:SignContracts:SignAdhesionTerm",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.open_dialog(
                                                                _vm.allocation
                                                                  .AdhesionTermSignatureKey,
                                                                _vm.docTypeEnum
                                                                  .AdhesionTerm
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.allocation.AdhesionTermSaved
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          title:
                                                            _vm.$t("download"),
                                                          "data-test":
                                                            "Dashboard:SignContracts:DownloadAdhesionTerm",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            function ($event) {
                                                              return _vm.downloadDocuments(
                                                                _vm
                                                                  .userFileEnums
                                                                  .ADHESION_TERM
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.allocation.InvestmentVehicle
                                      .ClosingType ===
                                    _vm.vehicleClosingOptionsEnum.CLOSING_SA
                                      ? _c(
                                          "tr",
                                          { staticClass: "content-row" },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "content-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$tc("bylaws", 1))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "content-date" },
                                              [_vm._v("-")]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "content-status" },
                                              [_vm._v("-")]
                                            ),
                                            _c("td", [
                                              _vm.allocation.InvestmentVehicle
                                                .BylawsUrl
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.allocation
                                                              .InvestmentVehicle
                                                              .BylawsUrl,
                                                          title:
                                                            _vm.$t("download"),
                                                          loading:
                                                            _vm.fileDownloading ==
                                                            _vm.allocation
                                                              .InvestmentVehicle
                                                              .BylawsUrl,
                                                          "data-test":
                                                            "Dashboard:SignContracts:DownloadBylaws",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            _vm.download_bylaws,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.allocation.InvestmentVehicle
                                      .ClosingType ===
                                    _vm.vehicleClosingOptionsEnum.CLOSING_SA
                                      ? _c(
                                          "tr",
                                          { staticClass: "content-row" },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "content-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "shareholders_agreement",
                                                        1
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "content-date" },
                                              [_vm._v("-")]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "content-status" },
                                              [_vm._v("-")]
                                            ),
                                            _c("td", [
                                              _vm.allocation.InvestmentVehicle
                                                .ShareholderAgreementUrl
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn-container",
                                                    },
                                                    [
                                                      _c("DxaButtonToTable", {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.allocation
                                                              .InvestmentVehicle
                                                              .ShareholderAgreementUrl,
                                                          title:
                                                            _vm.$t("download"),
                                                          loading:
                                                            _vm.fileDownloading ==
                                                            _vm.allocation
                                                              .InvestmentVehicle
                                                              .ShareholderAgreementUrl,
                                                          "data-test":
                                                            "Dashboard:SignContracts:DownloadShareholderAgreement",
                                                        },
                                                        on: {
                                                          btnFunction:
                                                            _vm.download_shareholder,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2404168287
                        ),
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            VSimpleTable,
                            { staticClass: "mt-3 bg-white" },
                            [
                              _c(
                                "thead",
                                {
                                  staticClass: "text-center",
                                  attrs: { id: "investor-table-header" },
                                },
                                [
                                  _c("tr", [
                                    _c("th", { attrs: { colspan: "2" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("bank_account_transfer_info")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "tbody",
                                { attrs: { id: "bank-row" } },
                                _vm._l(_vm.bankInfo, function (item, i) {
                                  return _c(
                                    "tr",
                                    { key: i, staticClass: "content-row" },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "investor-bank-table-item",
                                          attrs: { width: "60px" },
                                        },
                                        [_vm._v(" " + _vm._s(item.text) + " ")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "investor-bank-table-item investor-bank-table-item-value",
                                        },
                                        [_vm._v(" " + _vm._s(item.value) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      !_vm.showAllocationData
        ? _c("div", { staticClass: "allocation-formalization-section" }, [
            _c("div", { staticClass: "allocation-formalization-header" }, [
              _c("span", { staticClass: "allocation-formalization-title" }, [
                _vm._v(_vm._s(_vm.$t("formalization"))),
              ]),
            ]),
            _c("div", { staticClass: "allocation-formalization-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t("allocation_formalization")) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.clickSignDialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.clickSignDialog,
                callback: function ($$v) {
                  _vm.clickSignDialog = $$v
                },
                expression: "clickSignDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VRow,
                    {
                      staticClass: "py-2",
                      attrs: {
                        justify: "end",
                        align: "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "mr-3",
                          attrs: {
                            "data-test": "Dashboard:SignContracts:CloseDialog",
                          },
                          on: { click: _vm.reset_dialog },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c(VContainer, [
                    _c(
                      "div",
                      {
                        staticClass: "contract-div",
                        staticStyle: { "margin-bottom": "4px" },
                      },
                      [
                        _vm.originalFile
                          ? _c("iframe", {
                              attrs: {
                                src: _vm.originalFile,
                                width: "100%",
                                height: "100%",
                                "margin-bottom": "16px",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c("div", [
                      _c("div", { attrs: { id: "cs-tokenless-widget" } }),
                      _c(
                        "div",
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "dxa-btn-primary mt-6",
                              attrs: {
                                id: "submitButton",
                                "data-test": "Dashboard:SignContracts:Submit",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("send")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }